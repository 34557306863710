import React, { Fragment } from "react"
import LayoutGeneric from "../components/layoutGeneric"
import Meta from "../components/meta"

const NotFoundPage = () => {
  return (
    <Fragment>
      <Meta title="404" />
      <LayoutGeneric>
        <div className="grid place-items-center pt-10">
          <div className="text-xl font-medium pb-5">Ooops!</div>
          <div className="pt-5">
            <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 24 24">
              <path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1 6h2v8h-2v-8zm1 12.25c-.69 0-1.25-.56-1.25-1.25s.56-1.25 1.25-1.25 1.25.56 1.25 1.25-.56 1.25-1.25 1.25z" />
            </svg>
          </div>
          <div className="text-md pt-10">That didn't work well.</div>
        </div>
      </LayoutGeneric>
    </Fragment>
  )
}

export default NotFoundPage
